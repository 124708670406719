import React from 'react';
//import {Typography} from '@material-ui/core';
import { Link } from "react-router-dom";
import {Typography} from '@rmwc/typography';
import {Grid, GridCell} from '@rmwc/grid';
import {Button} from "@rmwc/button";
import {
    Card,
//    CardActionButton,
//    CardActionIcon,
//    CardActions,
    CardMedia,
//    CardActionButtons,
//    CardActionIcons,
    CardPrimaryAction
} from '@rmwc/card';

// import main_home_image from '../images/myimage.png'; // Tell webpack this JS file uses this image

// import {Icon} from '@rmwc/icon';
// import {BrightInkBannerImage} from './PageComponents';

/* the class HomePageContent below is identical to:

    function HomePageContent(props) {
        return <h1>Hello, {props.name}</h1>;
    }

    call both with

    with a Component, you can always set props when it's called.
*/
class PageContentHome extends React.Component {

    render() {
        return <>
            {
                // <BrightInkBannerImage imageUrl={"images/test_homepage_banner.jpg"}/>
                }
        <Grid className={"brightInk_content_width"}>
            <GridCell span={12}>
            </GridCell>
            <GridCell span={12}>
                <Typography use={"headline1"} className={"brightInk_headline1"}>Bright Ink: for Businesses</Typography>
                <Typography use={"headline2"} className={"brightInk_headline2"}>Systems Integration Across The Web</Typography>
                <Typography use={"subtitle1"}>Get your systems talking!</Typography>
                <Typography use={"body1"}>Whether you're taking orders manually, copying and pasting into and out of excel, or trawling through thousands of paypal payments to reconcile bank statements, we can help.
                    </Typography>
                <Typography use={"body1"}>The automation will be faster and more reliable than you - and it won't tear its hair out or swear at the data as it goes along.</Typography>

                    <Button outlined  theme={["secondaryBg", "onSecondary"]} tag={Link} to="/work_with_me" label={"Work with me"}/>
            </GridCell>
            <GridCell span={12}>
                <Typography use={"headline1"}>Bright Ink: for Parents</Typography>
                <Typography use={"subtitle1"}>I'm a parent and I'm a coder. So it goes without saying that I've built websites and services to help parents like me :)</Typography>
                <Typography use={"body1"}>It keeps me entertained, lets me play with whatever new technologies take my fancy, and helps fellow parents to forge their way in life.</Typography>
                <Typography use={"body1"}>I have 4 kids in local schools and a PhD in BioInformatics. I try to find a balance between 'expected social norms' and 'innate biology' that doesn't make me feel like a complete hypocrite.</Typography>
                <Typography use={"body1"}>Hence: baby pottying, cloth nappies, play based learning, barefoot shoes, barely any homework and as little screen time as we can get away with... but also mad school runs and the need to learn the times tables <i>somehow</i> without everyone ending up in tears! Oh, and an easy way to see what's for school dinner every day (if you happen to live in the London Borough of Havering).</Typography>
            </GridCell>
            <GridCell span={4}>
                <Card className={"brightInk_home_card"}>
                    <CardPrimaryAction onClick={()=>window.location.href="https://talkingtimestables.uk"}>
                        <CardMedia
                            sixteenByNine
                            style={{
                                backgroundImage: 'url(images/ttt_cartoon_logo.svg)',backgroundSize:'50%'
                            }}
                        />
                        <div style={{padding: '0 1rem 1rem 1rem'}}>
                            <Typography use="headline6" tag="h2">
                                Talking Times Tables
                            </Typography>
                            <Typography
                                use="subtitle2"
                                tag="h3"
                                theme="textSecondaryOnBackground"
                                style={{marginTop: '-1rem'}}
                            >
                                AWS DynamoDB, AWS Cognito, AWS storage, Twilio (automated voice calls, text messages), php, js, html5, cpanel, ssh, git, Amazon Publishing
                            </Typography>

                            <Typography use="body1">
                                Fully automated system that phones children every day and teaches them their times tables.
                            </Typography>
                            <Typography use="body1">
                                A ringing phone focuses attention far better than me waving times tables practice sheets.
                            </Typography>
                            <Typography use="body1">
                                Parents set the times for the calls. All lessons are pre-recorded.
                            </Typography>
                                </div>
                    </CardPrimaryAction>
                </Card>
            </GridCell>
            <GridCell span={4}>
                <Card className={"brightInk_home_card"}>
                    <CardPrimaryAction onClick={()=>window.location.href="https://whatsforschooldinner.uk"}>
                        <CardMedia
                            sixteenByNine
                            style={{
                                backgroundImage: 'url(images/wfsd_not_logo.jpg)'
                            }}
                        />
                        <div style={{padding: '0 1rem 1rem 1rem'}}>
                            <Typography use="headline6" tag="h2">
                                What's for School Dinner?
                            </Typography>
                            <Typography
                                use="subtitle2"
                                tag="h3"
                                theme="textSecondaryOnBackground"
                                style={{marginTop: '-1rem'}}
                            >
                                Expo &rarr; Android App, ios App, Play Store, App Store
                            </Typography>
                            <Typography use="body1" >
                                Find out what's on the menu today - at over 70 schools with pre-published menus that rotate every 3 weeks.
                            </Typography>
                            <Typography use="body1" >
                                I wrote this app so I could learn how to build apps - but I use it every day because school menus are infuriating!
                            </Typography>
                        </div>
                    </CardPrimaryAction>
                </Card>
            </GridCell>
            <GridCell span={4}>
                <Card className={"brightInk_home_card"}>
                    <CardPrimaryAction onClick={()=>window.location.href="https://bornready.uk"}>
                        <CardMedia
                            sixteenByNine
                            style={{
                                backgroundImage: 'url(images/bornready_flaparap.png )',backgroundSize:'cover'
                            }}
                        />
                        <div style={{padding: '0 1rem 1rem 1rem'}}>
                            <Typography use="headline6" tag="h2">
                                Born Ready
                            </Typography>
                            <Typography
                                use="subtitle2"
                                tag="h3"
                                theme="textSecondaryOnBackground"
                                style={{marginTop: '-1rem'}}
                            >
                                Online Courses, Physical Products, Mailchimp, Wordpress, Facebook Graph api
                            </Typography>
                            <Typography use="body1" >
                                Baby-led potty training. Help your newborn to use a potty and save yourself eleventy billion baby wipes.<br/>

                            </Typography>
                        </div>
                    </CardPrimaryAction>
                </Card>
            </GridCell>
            { /*
                <GridCell span={8}>
                    <Typography use={"headline2"}>Clients</Typography>
                    <Grid style={{padding: 0}}>
                        <GridCell span={6}>
                            <Card className={"brightInk_home_card"}>
                                <CardPrimaryAction>
                                    <CardMedia
                                        sixteenByNine
                                        style={{
                                            background: 'linear-gradient(90deg, rgba(131,58,180,1) 0%, rgba(253,29,29,1) 50%, rgba(252,176,69,1) 100%)',
                                        }}
                                    />
                                    <div style={{padding: '0 1rem 1rem 1rem'}}>
                                        <Typography use="headline6" tag="h2">
                                            Formula Botanica
                                        </Typography>
                                        <Typography
                                            use="subtitle2"
                                            tag="h3"
                                            theme="textSecondaryOnBackground"
                                            style={{marginTop: '-1rem'}}
                                        >
                                            Webhooks, Infusionsoft api, Woocommerce api, Xero api, Stripe api, back-end
                                            integration of order and accounting systems.
                                        </Typography>
                                        <Typography
                                            use="body1"
                                            tag="div"
                                            theme="textSecondaryOnBackground"
                                        >
                                            The world's largest online organic skincare school. When their term-time
                                            enrollment opens, a new student enrolls every few seconds.
                                        </Typography>
                                    </div>
                                </CardPrimaryAction>
                            </Card>
                            <Card className={"brightInk_home_card"}>
                                <CardPrimaryAction>
                                    <CardMedia
                                        sixteenByNine
                                        style={{
                                            background: 'linear-gradient(90deg, rgba(131,58,180,1) 0%, rgba(253,29,29,1) 50%, rgba(252,176,69,1) 100%)',
                                        }}
                                    />
                                    <div style={{padding: '0 1rem 1rem 1rem'}}>
                                        <Typography use="headline6" tag="h2">
                                            Juice HQ
                                        </Typography>
                                        <Typography
                                            use="subtitle2"
                                            tag="h3"
                                            theme="textSecondaryOnBackground"
                                            style={{marginTop: '-1rem'}}
                                        >
                                            Wordpress, Divi, Fully custom order prep and delivery functionality,
                                            Mailchimp.
                                        </Typography>
                                        <Typography
                                            use="subtitle2"
                                            tag="h3"
                                            theme="textSecondaryOnBackground"
                                            style={{marginTop: '-1rem'}}
                                        >
                                            Advised on converting to an online business.
                                        </Typography>
                                        <Typography
                                            use="body1"
                                            tag="div"
                                            theme="textSecondaryOnBackground"
                                        >
                                            Juice HQ press fresh fruit and vegetables to create delicious juices, which
                                            they deliver to within the local area.
                                        </Typography>
                                    </div>
                                </CardPrimaryAction>
                            </Card>
                        </GridCell>

                        <GridCell span={6}>
                            <Card className={"brightInk_home_card"}>
                                <CardPrimaryAction>
                                    <CardMedia
                                        sixteenByNine
                                        style={{
                                            background: 'linear-gradient(90deg, rgba(131,58,180,1) 0%, rgba(253,29,29,1) 50%, rgba(252,176,69,1) 100%)',
                                        }}
                                    />
                                    <div style={{padding: '0 1rem 1rem 1rem'}}>
                                        <Typography use="headline6" tag="h2">
                                            Mini Models Agency
                                        </Typography>
                                        <Typography
                                            use="subtitle2"
                                            tag="h3"
                                            theme="textSecondaryOnBackground"
                                            style={{marginTop: '-1rem'}}
                                        >
                                            Wordpress customisation, Typeform, Mailchimp configuration and api.
                                        </Typography>
                                        <Typography
                                            use="body1"
                                            tag="div"
                                            theme="textSecondaryOnBackground"
                                        >
                                            A child modelling agency with hundreds of models on their books. They place
                                            children on photo shoots for high street brands and fashion designers.

                                        </Typography>
                                    </div>
                                </CardPrimaryAction>
                            </Card>
                            <Card className={"brightInk_home_card"}>
                                <CardPrimaryAction>
                                    <CardMedia
                                        sixteenByNine
                                        style={{
                                            background: 'linear-gradient(90deg, rgba(131,58,180,1) 0%, rgba(253,29,29,1) 50%, rgba(252,176,69,1) 100%)',
                                        }}
                                    />
                                    <div style={{padding: '0 1rem 1rem 1rem'}}>
                                        <Typography use="headline6" tag="h2">
                                            Upminster Sports Massage and Pilates
                                        </Typography>
                                        <Typography
                                            use="subtitle2"
                                            tag="h3"
                                            theme="textSecondaryOnBackground"
                                            style={{marginTop: '-1rem'}}
                                        >
                                            Custom: Pdf creation from web forms, Wix.
                                        </Typography>
                                        <Typography
                                            use="body1"
                                            tag="div"
                                            theme="textSecondaryOnBackground"
                                        >
                                            A Pilates studio offering high teacher to student ratios for highly
                                            personalised instruction.
                                        </Typography>
                                    </div>
                                </CardPrimaryAction>
                            </Card>
                        </GridCell>
                    </Grid>
                </GridCell>
                */
            }
        </Grid>
    </>
    }
}

export default PageContentHome;