import React from "react";
import {Typography} from "@rmwc/typography";
import {Grid, GridCell} from "@rmwc/grid";
// import { Theme } from '@rmwc/theme'
import {BrightInkFooter} from "./PageComponents";

class PageFooter extends React.Component {
    render() {
        return <BrightInkFooter>
            <Grid  className={"brightInk_content_width"}>
                <GridCell className="brightInk_footer_left" desktop={6} tablet={4}  phone={4} align={"top"} >
                    <Typography use={"body2"}>Contact:<br/><a href={"mailto:jenn@brightinklab.uk"}>jenn@brightinklab.uk</a></Typography>
                </GridCell>
                {
                    // <GridCell className="brightInk_footer_left" desktop={6} tablet={4} phone={4} align={"top"}>
                        // <Typography use={"body2"}>Social (occasionally):<br/>Linked In<br/>Facebook<br/></Typography>
                    // </GridCell>
                }
                <GridCell className="brightInk_footer_right" desktop={6} tablet={4} phone={4} align={"top"}>
                    <Typography use={"body2"}>Bright Ink Links<br/>
                    <a href={"https://talkingtimestables.uk"}>Talking Times Tables</a><br/>
                    <a href={"https://whatsforschooldinner.uk"}>What's for School Dinner</a><br/>
                    <a href={"https://bornready.uk"}>Born Ready</a>
                    </Typography>
                </GridCell>
                <GridCell className="brightInk_footer_center" desktop={12}  tablet={8} phone={4} align={"middle"}>
                    <Typography use={"body2"}>Bright Ink Lab Ltd, registered in England, company number 11604398</Typography>
                </GridCell>
            </Grid>

        </BrightInkFooter>

    }
}

export default PageFooter;