import React from 'react';

// import PageContentAbout from './components/PageContent_About.jsx';
import PageContentHome from './components/PageContent_Home.jsx';
import PageContentWorkWithMe from './components/PageContent_WorkWithMe.jsx';
// import PageContentClients from './components/PageContent_Clients.jsx';
// import PageContentProjects from './components/PageContent_Projects.jsx';
//import PageContentDynamic from './components/PageContent_Dynamic.jsx';
import PageHeader from './components/PageHeader.jsx';
import PageFooter from './components/PageFooter.jsx';

//import 'material-components-web/material-components-web.scss';
//import '@material/react-material-icon/index.scss';
//import Ripple from 'material/ripple';


//import {TopAppBar, MaterialIcon} from 'react-material-components-web'
//import { Button} from '@react-mdc/button';
//import { SimpleTopAppBar } from '@rmwc/top-app-bar'
// import { Theme } from '@rmwc/theme'
import { ThemeProvider } from '@rmwc/theme'
// import { Grid } from '@rmwc/grid'
import { RMWCProvider } from '@rmwc/provider'
// import { IconButton } from '@rmwc/icon-button';
// import { Icon } from '@rmwc/icon';

import { BrowserRouter as Router, Route, Switch } from "react-router-dom";

import '@material/button/dist/mdc.button.css'
import '@material/icon-button/dist/mdc.icon-button.css'
import '@material/top-app-bar/dist/mdc.top-app-bar.css'
// import {TopAppBarRow} from "@rmwc/top-app-bar";

class App extends React.Component {
    render() {

        return <Router>
                <Switch>
                    <Route path="/home">
                        <BrightInkPage
                            page_to_display={"home"}
                            title_bar_text={"Bright Ink Lab"}
                        />
                    </Route>
                    <Route path="/work_with_me">
                        <BrightInkPage
                            page_to_display={"work_with_me"}
                            title_bar_text={"Bright Ink Lab"}
                        />
                    </Route>
                        { /*
                    <Route path="/about">
                        <BrightInkPage
                            page_to_display={"about"}
                            title_bar_text={"Bright Ink Lab"}
                        />
                    </Route>
                    <Route path="/projects">
                        <BrightInkPage
                            page_to_display={"projects"}
                            title_bar_text={"Bright Ink Lab"}
                        />
                    </Route>
                    <Route path="/clients">
                        <BrightInkPage
                            page_to_display={"clients"}
                            title_bar_text={"Bright Ink Lab"}
                        />
                    </Route>
                    <Route path="/dynamic">
                        <BrightInkPage
                            page_to_display={"dynamic"}
                            title_bar_text={"Bright Ink Lab"}
                        />
                    </Route>
                    <Route path="/test">
                        <BrightInkPage
                            page_to_display={"test"}
                            title_bar_text={"Test title"}
                        />
                    </Route>
                    */}
                    <Route path="/">
                        <BrightInkPage
                            page_to_display={"home"}
                            title_bar_text={"Bright Ink Lab"}
                        />
                    </Route>
                </Switch>
            </Router>
    }
}

/**
 * expects:
 * page_to_display="home", "about", etc
 * title_bar_text = "Bright Ink Lab: Home" etc
 */
class BrightInkPage extends React.Component {

    getPageContent(){
        switch(this.props.page_to_display) {
            case 'work_with_me' :
                return <PageContentWorkWithMe />
                /*
            case 'about' :
                return <PageContentAbout />
            case 'projects' :
                return <PageContentProjects />
            case 'clients' :
                return <PageContentClients />

                 */
            default :
                return <PageContentHome />
        }
    }

    render() {

        return <>
            <RMWCProvider
                // Globally disable ripples
                ripple={false}
                // Global options for icons
                // Takes the same options as the icon component
                icon={{
                    basename: 'material-icons'
                }}
                // Global options for typography
                // allows mapping of a defaultTag or specific classes
                // See the Typography docs for more info
                typography={{
                    defaultTag: 'div',
                    headline1: 'h1',
                    headline2: 'h2',
                    headline3: 'h3',
                    headline4: 'h4',
                    headline5: 'h5',
                    headline6: 'h6',
                    body1: 'p',
                    body2: 'p',
                    subtitle1: 'h4',
                    subtitle2: 'h4'

                }}
                // Global options for tooltips
                // Takes most of the options for tooltips
                // See the Tooltip docs for more info
                tooltip={{
                    align: 'right'
                }}
            >
            <ThemeProvider
                options={{
                    // style all @rmwc components via simple styling
                    primary: '#6665dd', //'#1B6535'
                    secondary: '#6665dd', //#A8C66C
                    onPrimary: 'white',
                    onSecondary: 'white'

                }}
            >
                <PageHeader title_bar_text={this.props.title_bar_text} page_to_display={this.props.page_to_display}/>

                    { this.getPageContent() }

                <PageFooter />
            </ThemeProvider>
            </RMWCProvider>
        </>
    }
}


export default App;


