import React from "react";
// import {BrightInkBannerImage} from "./PageComponents";
import {Grid, GridCell} from "@rmwc/grid";
import {Typography} from "@rmwc/typography";

class PageContentWorkWithMe extends React.Component {
    render() {
        return <>
            {
                // <BrightInkBannerImage imageUrl={"images/test_homepage_banner.jpg"}/>
            }
            <Grid className={"brightInk_content_width"}>
                <GridCell span={12}>
                    <Typography use={"headline1"} className={"brightInk_headline1"}>Work with me</Typography>
                    <Typography use={"headline2"} className={"brightInk_headline2"}>Websites, Bespoke Functionality, Systems Integration</Typography>
                    <Typography use={"subtitle1"}></Typography>

                    <Typography use={"body1"}>
                        If you have time-consuming manual steps in your business processes, the chances are they're:
                    </Typography>
                    <Typography use={"body1"}>
                        a) Driving you crazy,<br/>
                        b) Prone to inaccuracy,<br/>
                        c) Taking up time that could be better spent elsewhere.
                    </Typography>
                    <Typography use={"body1"}>
                        When clients tell me 'you've changed my life!' they're not talking about grand epiphanies.
                    </Typography>
                    <Typography use={"body1"}>
                        They're talking about time-consuming, repetitive, often complicated tasks that now 'happen by magic' giving them time to get on with other things.
                    </Typography>
                    <Typography use={"body1"} style={{fontStyle:"italic"}}>
                        If you can explain how you do it, I can automate it.
                    </Typography>
                    <Typography use={"body1"}>
                        It's not all that radical - but it feels amazing!
                    </Typography>
                    <Typography use={"body1"}>
                        Ad-hoc rate: &pound;75/hr<br/>
                        Project rate: priced individually.
                    </Typography>
                    <Typography use={"body1"}>
                        And yes, it is completely worth it!
                    </Typography>
                </GridCell>
                <GridCell span={12}>
                    <Typography use={"headline2"}>Business Expertise</Typography>
                    <Typography use={"subtitle1"}></Typography>
                    <Typography use={"body1"}>
                        Together we can identify where automation could help your business.
                    </Typography>
                    <Typography use={"body1"}>
                        <b>I know how to make things happen that you might not even realise are possible!</b>
                    </Typography>
                    <Typography use={"body1"}>
                        I have extensive experience in online business (e-commerce for physical goods, services and knowledge products / online courses).
                    </Typography>
                    <Typography use={"body1"}>
                        A solid understanding of online marketing, sales funnels, landing pages, lead magnets, upsells and downsells etc.
                    </Typography>
                    <Typography use={"body1"}>
                        Experience with Facebook and Instagram advertising.
                    </Typography>
                </GridCell>
                <GridCell span={12}>
                    <Typography use={"headline2"}>Technology</Typography>
                    <Typography use={"subtitle1"}>I work with the following technologies:</Typography>
                    <Typography use={"body1"}>
                        Web: php, js, html5, css, react, mysql
                    </Typography>
                    <Typography use={"body1"}>
                        Software, services and apis:<br/>
                        Wordpress, Woocommerce, Divi (+ apis)<br/>
                        Xero (+ api)<br/>
                        Infusionsoft (+ api)<br/>
                        Google apis<br/>
                        Monday.com (+ api)<br/>
                        Stripe (+ api)<br/>
                        Mailchimp (+ api)<br/>
                        Zapier<br/>
                        AWS: DynamoDB (nosql database), Cognito (authentication), S3<br/>
                        Twilio (+ api)<br/>
                        Wix, SquareSpace
                    </Typography>
                    <Typography use={"body1"}>
                        For more details see my Linked In profile.
                    </Typography>
                </GridCell>

            </Grid>
        </>

    }
}
export default PageContentWorkWithMe;