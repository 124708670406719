import React from "react";
// import {TopAppBar, TopAppBarRow, TopAppBarSection, TopAppBarTitle, TopAppBarNavigationIcon, TopAppBarActionItem, TopAppBarFixedAdjust} from "@rmwc/top-app-bar";
// import {Button} from "@rmwc/button"
// import {Grid, GridCell} from "@rmwc/grid"
// import { Link } from "react-router-dom";
// import { Hidden } from '@material-ui/core';
// import { ThemeProvider } from "@rmwc/theme"
import {Theme,
//    ThemeProvider
} from "@rmwc/theme"
// import {Checkbox} from "@rmwc/checkbox"
// import {Radio} from "@rmwc/radio"

class BrightInkBannerImage extends React.Component {

    render() {

        return <div class={"brightInk_banner_image_background"}>
            <div class={"brightInk_banner_image"} style={{backgroundImage:"url("+this.props.imageUrl+")", backgroundPosition:"center"}}>
            <div class={"brightInk_content_width"}>
                {this.props.children}
            </div>
            </div>
        </div>

    }
}

class BrightInkFooter extends React.Component {

    render() {

        return <Theme use={['primaryBg', 'onPrimary']} wrap>
            <div className={"brightInk_footer"}>
                {this.props.children}
            </div>
            </Theme>
    }
}

export {BrightInkBannerImage, BrightInkFooter};